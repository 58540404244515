import React from 'react';
import { Link } from 'react-router-dom';
import FooterDesktop from './FooterDesktop';

const ClasesGym = () => {
  return (

    <div>

      <div className="py-1 col-sm-12 col-md-12 col-lg-12 col-xl-12 card bg-primary border-0 rounded-0">
        <h5 className="promocion-text"> Obten tu{" "}
          <a className="free" href="https://wa.me/+51924209393/?text=" target="_blank" rel="noreferrer">FREE PASS</a>{" "}y consigue PRECIOS ESPECIALES.&nbsp;
          <span className="blanco"></span>
        </h5>
      </div>

      <div className="d-flex">
        {/* Navbar Superior */}
        <div className="content w-100">
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-5">
            <div className="d-flex align-items-center">
              <Link to="/" className="navbar-brand js-scroll-trigger">
                <img className="imglogo" src="assets/img/logos/Logo para fondo negro.png" alt="" />
              </Link>
              <Link to="/formulario" className="btn btn-primary ml-3">Mi perfil</Link>
            </div>


            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span className="ti-menu text-primary"></span>
            </button>
            <div className=" nav justify-content-end collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link to="/" className="nav-link active js-scroll-trigger">Home</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/planes" className="nav-link active js-scroll-trigger">Planes</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/clases" className="nav-link active js-scroll-trigger">Clases</Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

        </div>
        {/* END Navbar Superior */}

      </div>


      <section className="fondo-azul wt-section" id=''>
        <div className="container">
          <div className="row justify-content-md-center text-center mb-lg-5 mb-4 pb-lg-5">
            <div className="col-md-12">
              <h2 className="dest-text h1">Ofrecemos Diferentes Actividades</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6 mb-5">
              <figure data-aos-easing="linear" data-aos-delay="100">
                <img className="w-100 rounded-top" src="assets/img/clases/aerobicos.jpg" alt="gym" />
                <div className="fondo-celeste wt-box-shadow-sm text-center rounded p-4">
                  <div className="mb-3">
                    <h5 className="mb-1">AEROBICOS</h5>
                  </div>
                </div>
              </figure>
            </div>

            <div className="col-lg-3 col-sm-6 mb-5">
              <figure data-aos-easing="linear" data-aos-delay="200">
                <img className="w-100 rounded-top" src="assets/img/clases/fullbody.jpg" alt="gym" />
                <div className="fondo-celeste wt-box-shadow-sm text-center rounded p-4">
                  <div className="mb-3">
                    <h5 className="mb-1">FULL BODY</h5>
                  </div>
                </div>
              </figure>
            </div>

            <div className="col-lg-3 col-sm-6 mb-5">
              <figure data-aos-easing="linear" data-aos-delay="400">
                <img className="w-100 rounded-top" src="assets/img/clases/baile.jpg" alt="gym" />
                <div className="fondo-celeste wt-box-shadow-sm text-center rounded p-4">
                  <div className="mb-3">
                    <h5 className="mb-1">BAILE</h5>
                  </div>
                </div>
              </figure>
            </div>

            <div className="col-lg-3 col-sm-6 mb-5">
              <figure data-aos-easing="linear" data-aos-delay="300">
                <img className="w-100 rounded-top" src="assets/img/clases/fitboxing.jpg" alt="gym" />
                <div className="fondo-celeste wt-box-shadow-sm text-center rounded p-4">
                  <div className="mb-3">
                    <h5 className="mb-1">FIT BOXING</h5>
                  </div>
                </div>
              </figure>
            </div>

            <div className="col-lg-3 col-sm-6 mb-5">
              <figure data-aos-easing="linear" data-aos-delay="400">
                <img className="w-100 rounded-top" src="assets/img/clases/topfighter.jpg" alt="gym" />
                <div className="fondo-celeste wt-box-shadow-sm text-center rounded p-4">
                  <div className="mb-3">
                    <h5 className="mb-1">TOP FIGHTER</h5>
                  </div>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </section>

      <FooterDesktop />

    </div>

  );
};

export default ClasesGym;