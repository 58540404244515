import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { useAuthenticator } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import { createUsuarios } from './graphql/mutations';
import { getUsuarios } from './graphql/queries';
import { evaluacionsByUsuarioEmail } from './graphql/queries';
import './styles/Formulario.css';
import Chart from 'chart.js/auto';
import { differenceInYears } from 'date-fns';
import FooterDesktop from './FooterDesktop';
import ClassCard from './ClassCard';
import FooterMovile from './FooterMovile';
import PerfilSocio from './PerfilSocio';

Amplify.configure(awsExports);


function Formulario() {
  const { user } = useAuthenticator();
  const customNombre = user?.attributes?.['custom:Nombre'];
  const customApellido = user?.attributes?.['custom:Apellido'];
  const customNumeroDeTelefono = user?.attributes?.['custom:NumeroDeTelefono'];
  const customDocumentoDeIdentidad = user?.attributes?.['custom:DocumentoDeIdentidad'];
  const customGenero = user?.attributes?.['custom:Genero'];
  const sub = user?.attributes?.sub;
  const userEmail = user?.attributes?.email;
  const userBirthdate = user?.attributes?.birthdate;
  const [userData, setUserData] = useState({ edad: null, });
  const [userEvaluations, setUserEvaluations] = useState([]);
  const chartRef = useRef();
  const [selectedComponent, setSelectedComponent] = useState('clasesCreadas');
  const [selectedIcon, setSelectedIcon] = useState('clasesCreadas');


  const handleIconClick = (iconName) => {
    // Actualiza el estado para indicar qué componente renderizar
    setSelectedComponent(iconName);
    setSelectedIcon(iconName);
  };


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await API.graphql(graphqlOperation(getUsuarios, { email: userEmail }));
        // Calcular la edad
        const edad = userBirthdate ? differenceInYears(new Date(), new Date(userBirthdate)) : null;

        // Actualiza el estado con la edad calculada
        setUserData({
          ...userData,
          edad: edad,
        });


        // Verificar si el usuario ya tiene un registro en la base de datos
        if (!userData.data.getUsuarios) {
          // Si no hay un registro, entonces crea uno
          const timestampRegistro = Math.floor(new Date().getTime() / 1000);
          const estadoDelUsuario = 'Cliente Nuevo';
          const fechaDesde = '';
          const fechaHasta = '';
          const codigoDeUsuario = '';
          const edad = userBirthdate ? differenceInYears(new Date(), new Date(userBirthdate)) : null;
          const ultimaAsistencia = '0000-00-00';
          const diasDeCongelamiento = 0;

          try {
            await API.graphql(graphqlOperation(createUsuarios, {
              input: {
                email: userEmail,
                birthdate: userBirthdate,
                nombre: customNombre,
                apellido: customApellido,
                telefono: customNumeroDeTelefono,
                documentoDeIdentidad: customDocumentoDeIdentidad,
                generoDelUsuario: customGenero,
                fechaDeRegistro: timestampRegistro,
                estadoDelUsuario: estadoDelUsuario,
                fechaDesde: fechaDesde,
                fechaHasta: fechaHasta,
                codigoDeUsuario: codigoDeUsuario,
                id: sub,
                edad: edad,
                diasDeCongelamiento: diasDeCongelamiento,
                ultimaAsistencia: ultimaAsistencia
              }
            }));

            console.log("Registro de usuario creado");
          } catch (error) {
            console.error("Error al crear el registro de usuario:", error);
          }
        } else {
          // Si el usuario ya tiene un registro, simplemente establece los datos en el estado
          const user = userData.data.getUsuarios;
          setUserData(user);
          console.log('Datos del usuario:', user);
        }

      } catch (error) {
        console.error('Error al obtener los datos del usuario:', error);
      }
    };

    const fetchUserEvaluations = async () => {
      try {
        const response = await API.graphql(graphqlOperation(evaluacionsByUsuarioEmail, { usuarioEmail: userEmail }));
        const sortedEvaluations = response.data.evaluacionsByUsuarioEmail.items.sort(
          (a, b) => new Date(a.fechaDeEvaluacion) - new Date(b.fechaDeEvaluacion)
        );
        setUserEvaluations(sortedEvaluations);
      } catch (error) {
        console.error('Error al obtener las evaluaciones del usuario:', error);
      }
    };


    fetchUserData();
    fetchUserEvaluations();
  }, [userEmail, userBirthdate, customNombre, customApellido, customNumeroDeTelefono, customGenero, customDocumentoDeIdentidad, sub]);

  // Preparar datos para el gráfico de progreso
  const chartData = useMemo(() => {
    return {
      labels: userEvaluations.map((evaluacion) => evaluacion.fechaDeEvaluacion),
      datasets: [
        {
          label: 'Peso',
          data: userEvaluations.map((evaluacion) => evaluacion.peso),
          fill: false,
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 2,
        },
      ],
    };
  }, [userEvaluations]);


  useEffect(() => {
    let chartInstance;

    if (userEvaluations.length > 0) {
      // Destruir gráfico anterior
      if (chartInstance) {
        chartInstance.destroy();
      }

      // Crear nuevo gráfico
      chartInstance = new Chart(chartRef.current, {
        type: 'line',
        data: chartData,
      });
    }

    return () => {
      // Limpiar al desmontar el componente
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [userEvaluations, chartData]);



  return (
    <>

      {/* Navbar */}
      <nav className="navbar-socio">
        {/* Logo y Bienvenida */}
        <div className="menu-izquierdo">
          <Link to="/">
            <img className="imglogo" src="assets/img/logos/Logo para fondo negro.png" alt="" />
          </Link>
          <h6 className='text-white'>{customNombre} {customApellido}</h6>
        </div>
        {/* End Logo y Bienvenida */}

        {/* Menu Central en Deskatop */}
        <div className='menu-central'>
          <i
            className={`fa-duotone fa fa-dumbbell ${selectedIcon === 'clasesCreadas' ? 'selected' : ''}`}
            onClick={() => handleIconClick('clasesCreadas')}
          ></i>

          <i
            className={`fa-solid fa fa-user ${selectedIcon === 'perfilSocio' ? 'selected' : ''}`}
            onClick={() => handleIconClick('perfilSocio')}
          ></i>
        </div>
        {/* END Menu Central en Deskatop */}

        {/* Menu Derecho */}
        <div className="menu-derecho">
          <Link to="/">Home</Link>
          <Link to="/planes">Planes</Link>
          <Link to="/clases">Clases</Link>
        </div>
        {/* END Menu Derecho */}

      </nav>
      {/* End Navbar */}

      {/* body */}
      <div className='body-formulario'>
        {/* Renderizar el componente según la selección del ícono */}
        {selectedComponent === 'clasesCreadas' && (
          <section className="clasesCreadas">
            <ClassCard sub={sub} nombre={customNombre} apellido={customApellido} phoneNumber={customNumeroDeTelefono} />
          </section>
        )}
        {selectedComponent === 'perfilSocio' && (
          <PerfilSocio />
        )}

        {/* Nueva sección para mostrar datos de evaluaciones */}
        {/* {userEvaluations.length > 0 && (
        <div className='ml-4'>
          <h5>Mis Evaluaciones</h5>
          <ul>
            {userEvaluations.map((evaluacion) => (
              <li key={evaluacion.id}>
                Fecha: {evaluacion.fechaDeEvaluacion}, Peso: {evaluacion.peso}, IGC: {evaluacion.igc}
              </li>
            )).reverse()}
          </ul>
        </div>
      )} */}


        {/* Nueva sección para mostrar gráfico de progreso */}
        {/* {userEvaluations.length > 0 && (
        <div className="grafico-evaluacion mt-4">
          <h5>Gráfico de Progreso</h5>
          <Line data={chartData} />
        </div>
      )} */}
      </div>
      {/* END body */}

      <FooterMovile onIconClick={handleIconClick} />

      <FooterDesktop />

    </>
  );
}
export default Formulario;