import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { getUsuarios } from './graphql/queries';
import './styles/PerfilSocio.css';
import CerrarSesion from './CerrarSesion';

function PerfilSocio({ user }) {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await API.graphql(graphqlOperation(getUsuarios, { email: user.attributes.email }));
        const userFromAPI = response.data.getUsuarios;
        setUserData(userFromAPI);
      } catch (error) {
        console.error('Error al obtener los datos del usuario:', error);
      }
    };

    fetchUserData();
  }, [user]);

  if (!userData) {
    return <div className="spinner-border text-dark" role="status">
      <span className="sr-only">Loading...</span>
    </div>;
  }

  return (
    <div className='perfilSocio-container'>
      <div className='logo-beatforce-container'>
        <img className='logo-beatforce-perfilSocio' src="assets/img/logos/Logo principal.png" alt="logo-beatforce" />
      </div>
      <h2>{userData.estadoDelUsuario} {userData.codigoDeUsuario}</h2>
      <p>{userData.nombre} {userData.apellido}</p>
      <p>Teléfono: {userData.telefono}</p>
      <p>DNI: {userData.documentoDeIdentidad}</p>
      <p>Cumpleaños: {userData.birthdate}</p>
      <p>Inicio de Membresia: {userData.fechaDesde}</p>
      <p>Fin de Membresia: {userData.fechaHasta}</p>
      {/* Agrega más campos según sea necesario */}
      <CerrarSesion />
    </div>
  );
}

export default withAuthenticator(PerfilSocio);