import React from 'react';
import { Link } from 'react-router-dom';
import FooterDesktop from './FooterDesktop';

const GymComponent = () => {
  return (
    <div>
      {/* NAVBAR */}
      <header id="top">
        <div className="py-1 col-sm-12 col-md-12 col-lg-12 col-xl-12 card bg-primary border-0 rounded-0 fixed-top">
          <h5 className="promocion-text">Obten tu <a className="free" href="https://wa.me/+51924209393/?text=" target="_blank" rel="noreferrer">FREE PASS</a> y consigue PRECIOS ESPECIALES.&nbsp;<span className="blanco"></span></h5>
        </div>


        <nav className="menu-top my-5 navbar navbar-expand-lg navbar-dark bg-white fixed-top" id="mainNav">
          <div className="container">
            <div className="d-flex align-items-center">
              <Link to="./" className="navbar-brand js-scroll-trigger">
                <img className="imglogo" src="assets/img/logos/Logo para fondo negro.png" alt="logo-beatforce" />
              </Link>
              <Link to="./login" className="btn btn-primary ml-3">Login</Link>
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              <span className="ti-menu text-primary"></span>
            </button>
            <div className="nav justify-content-end collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to="/" className="nav-link active js-scroll-trigger">Home</Link>
                </li>
                <li className="nav-item">
                  <Link to="/planes" className="nav-link active js-scroll-trigger">Planes</Link>
                </li>
                <li className="nav-item">
                  <Link to="/clases" className="nav-link active js-scroll-trigger">Clases</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <section id="principal" className="wt-section-header hero-large hero mt-md-5">
          <div className="hero-img bg-overlay" data-overlay="0" style={{ backgroundImage: "url(assets/img/intro/img5.jpg)" }}></div>
          <div className="pt-4 container">
            <div className="row align-items-center text-white">
              <div className="col-md-7 alto-titulo">
                <h1>
                  UNA NUEVA EXPERIENCIA DE HACER FITNESS
                </h1>

                <div className="card bg-primary div-preventa">
                  <h2 className="preventa-text">Pregunta todas las promociones&nbsp;&nbsp;<a href="https://wa.me/+51924209393/?text=" target="_blank" rel="noreferrer">
                    <img src="assets/img/logos/logo-wasap.png" width="50" height="50" alt="WhatsApp Logo" />
                  </a></h2>
                </div>
              </div>
            </div>
          </div>
          <div className="magdalena text-center">
            <h2 style={{ fontSize: "20px" }} className="magdalena-text">Ahora en Magdalena</h2>
          </div>
        </section>
      </header>
      {/* END NAVBAR */}

      <main role="main">

        <section className="fondo-azul wt-section" id="portfolio">
          <div className="container-fluid">
            <div className="row justify-content-md-center text-center pb-lg-5">
              <div className="col-md-12">
                <h2 className="h1" style={{ color: 'white' }}>Conoce nuestras zonas en el <span className="titulo-anaranjado">gimnasio</span></h2>
                <p className="dest-text">Descubre las diferentes alternativas para complementar tu cuerpo.</p>
              </div>
            </div>

            <div className="container" style={{ backgroundColor: 'white', paddingTop: '20px', paddingBottom: '20px', borderRadius: '20px' }}>
              <div className="row">
                <div className="col-lg-3 col-md-4 col-6 col-sm my-vertical-spacing">
                  <div className="rounded-md overflow-hidden border">
                    <img className="img-fluid img-responsive" src="assets/img/portfolio/musculacion.jpg" alt="" />
                  </div>
                  <p className="zona-text">MUSCULACIÓN</p>
                </div>

                <div className="col-lg-3 col-md-4 col-6 col-sm my-vertical-spacing">
                  <div className="rounded-md overflow-hidden">
                    <img className="img-fluid img-responsive" src="assets/img/portfolio/cycling.jpg" alt="" />
                  </div>
                  <p className="zona-text">CYCLING</p>
                </div>
                <div className="col-lg-3 col-md-4 col-6 col-sm my-vertical-spacing">
                  <div className="rounded-md overflow-hidden">
                    <img className="img-fluid img-responsive" src="assets/img/portfolio/baile.jpg" alt="" />
                  </div>
                  <p className="zona-text">BAILE</p>
                </div>
                <div className="col-lg-3 col-md-4 col-6 col-sm my-vertical-spacing">
                  <div className="rounded-md overflow-hidden">
                    <img className="img-fluid img-responsive" src="assets/img/portfolio/funcional.jpg" alt="" />
                  </div>
                  <p className="zona-text">FUNCIONAL</p>
                </div>
              </div>
            </div>




          </div>
        </section>


        <div className="wt-section" id="price">
          <div className="container">
            <div className="row justify-content-md-center text-center mb-lg-5 mb-4 pb-lg-5">
              <div className="col-md-12">
                <h2 className="h1">Beneficios de entrenar en</h2>
                <h3 className="anaranjado">BEAT FORCE</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table custom-table table-borderless">
                    <thead>
                      <tr>
                        <th className="socio-crema">Amplio Horario de Atencion</th>
                        <th className="">Trainers Capacitados</th>
                        <th className="socio-crema">Evaluaciones al Personal</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr data-aos-easing="linear" data-aos-delay="200">
                        <td>
                          <span className="text-muted">Lunes a Viernes: 06:00 a.m - 11:00 p.m</span><br />
                          <span className="text-muted">Sabado: 08:00 a.m - 06:00 p.m</span><br />
                          <span className="text-muted">Domingo: 09:00 a.m - 03:00 p.m</span>
                        </td>
                        <td>
                          <span className="text-muted">Entrenamiento dirigido por staff de <br />instructores profesionales y con suma <br />experiencia.</span>
                        </td>
                        <td>
                          <span className="text-muted">Evaluacion fisica y orientacion <br />nutricional</span>
                        </td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th className="">Entrenamiento Personalizado</th>
                        <th className="socio-crema">Clases Grupales Variadas</th>
                        <th className="">Extension de Beneficios</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr data-aos-easing="linear" data-aos-delay="200">
                        <td>
                          <span className="text-muted">Diseño de rutina personalizado y <br />seguimiento de resultados mensuales.</span><br />
                        </td>
                        <td>
                          <span className="text-muted">Aerobicos, Fullbody, Afro, Baile,<br /> Ex Local, Fit Boxing, Top Fighter.</span>
                        </td>
                        <td>
                          <span className="text-muted">Familiares directos (Hijos, padres, <br />conyuges y/o hermanos) gozan de las mismas<br /> posibilidades de beneficio.</span>
                        </td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th className="socio-crema">Servicio de Congelamiento</th>
                        <th className="">Evaluacion Nutricional</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr data-aos-easing="linear" data-aos-delay="200">
                        <td>
                          <span className="text-muted">Hasta un mes de congelamiento<br />de tu membresia.</span><br />
                        </td>
                        <td>
                          <span className="text-muted">Diseño de rutina personalizado y <br />seguimiento de resultados mensuales.</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>

      <FooterDesktop />

    </div>
  );
};

export default GymComponent;
