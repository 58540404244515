import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listClases } from './graphql/queries';
import { createInscritos } from './graphql/mutations';
import { updateClases } from './graphql/mutations';
import { listInscritos } from './graphql/queries';
import './styles/ClassCard.css';

function ClassCard({ sub, nombre, apellido, phoneNumber }) {
  const [clases, setClases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchClases();
  }, []);

  const fetchClases = async () => {
    try {
      const classesData = await API.graphql(graphqlOperation(listClases));
      console.log("Datos de las clases:", classesData);

      const today = new Date();
      today.setHours(0, 0, 0, 0); // Establecer la hora a medianoche

      const todayClasses = classesData.data.listClases.items.filter(clase => {
        const classDate = new Date(clase.fecha * 1000);
        classDate.setHours(0, 0, 0, 0); // Establecer la hora a medianoche

        return (
          classDate.getTime() === today.getTime()
        );
      });

      console.log("Todas las clases de hoy:", todayClasses);
      setClases(todayClasses);
      setIsLoading(false);
    } catch (error) {
      console.error("Error al obtener las clases", error);
      setIsLoading(false);
    }
  };

  const handleReservarClase = async (clase) => {
    // Obtén todas las reservas del usuario
    const userReservations = await API.graphql(graphqlOperation(listInscritos, {
      filter: {
        inscrito: {
          eq: sub
        },
        tipo: {
          eq: clase.tipo
        },
        time_clase: {
          eq: clase.fecha
        }
      }
    }));

    // Comprueba si el usuario ya ha reservado esta clase
    if (userReservations.data.listInscritos.items.length > 0) {
      console.log("Ya has reservado esta clase");
      alert("Ya has reservado esta clase");
      return;
    }

    // Calcula el tiempo restante hasta el inicio de la clase
    const currentTime = new Date().getTime() / 1000;
    const timeRemaining = clase.fecha - currentTime;

    // Verifica si es posible reservar la clase (una hora antes del inicio)
    if (clase.cupo === 0 || timeRemaining > 3600) {
      console.log("No puedes reservar esta clase en este momento");
      alert("No puedes reservar esta clase en este momento");
      return;
    }

    // Si el usuario no ha reservado la clase y es posible reservar, procede con la reserva
    const timestampActual = new Date().getTime() / 1000;
    try {
      await API.graphql(graphqlOperation(createInscritos, {
        input: {
          tipo: clase.tipo,
          timestamp: timestampActual,
          inscrito: sub,
          nombre: nombre,
          time_clase: clase.fecha,
          apellido: apellido,
          phoneNumber: phoneNumber,
        }
      }));

      // Descuenta 1 cupo de la clase
      const updatedCupo = clase.cupo - 1;

      try {
        await API.graphql(graphqlOperation(updateClases, {
          input: {
            tipo: clase.tipo,
            fecha: clase.fecha,
            cupo: updatedCupo
          }
        }));

        console.log("Reserva exitosa");
        alert(`${clase.tipo}\n${new Date(clase.fecha * 1000).toLocaleString()}\nReserva exitosa`);
        // Vuelve a obtener las clases después de la reserva exitosa
        fetchClases();
      } catch (error) {
        console.error('Error al actualizar el cupo:', error);
      }
    } catch (error) {
      console.error('Error al realizar la reserva:', error);
    }
  };

  if (isLoading) {
    return <div className="spinner-border text-dark" role="status">
      <span className="sr-only">Loading...</span>
    </div>;
  }


  return (
    <>
      {clases.map((clase) => (
        <div className="class-card" key={clase.tipo}>
          <p>{new Date(clase.fecha * 1000).toLocaleString()}</p>
          <h2>{clase.tipo}</h2>
          <p>Instructor: {clase.instructor}</p>
          <p>Cupos Disponibles: {clase.cupo}</p>
          <div className='dividirCard'></div>
          <div className="button-container">
            {(() => {
              // Calcula el tiempo restante hasta el inicio de la clase
              const currentTime = new Date().getTime() / 1000;
              const timeRemaining = clase.fecha - currentTime;

              // Verifica si es posible reservar la clase (una hora antes del inicio)
              if (clase.cupo === 0) {
                return <p>Cupos agotados</p>;
              } else if (timeRemaining > 3600) {
                return <p>Podrás reservar esta clase 1 hora antes de su inicio</p>;
              } else if (timeRemaining <= 0) {
                return <p>Clase Cerrada</p>;
              } else {
                return <button className='btn btn-primary' onClick={() => handleReservarClase(clase)}>Reservar Clase</button>;
              }
            })()}
          </div>
        </div>
      ))}
    </>
  );


}

export default ClassCard;