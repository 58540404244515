import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import GymComponent from './GymComponent';
import Formulario from './Formulario';
import PlanesGym from './PlanesGym';
import ClasesGym from './ClasesGym';
import Coaches from './Coaches';
import { Authenticator } from '@aws-amplify/ui-react';
import Login from './Login';
import AdminCreateClass from './AdminCreateClass';
import AdminRegisteredCustomers from './AdminRegisteredCustomers';
import { useTheme, View, Image } from '@aws-amplify/ui-react';



const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Link to="/">
          <Image
            alt="BeatForcePeru logo"
            src="/assets/img/logos/Logo para fondo blanco.png"
            style={{
              width: '200px',
              height: '150px'
            }}
          />
        </Link>
      </View>
    );
  },
};

const formFields = {
  signUp: {
    'custom:Nombre': {
      order: 1,
      label: "Nombre",
      rules: [{ required: true, message: 'Por favor ingresa tu nombre' }],
      placeholder: 'Primer Nombre'
    },
    'custom:Apellido': {
      order: 2,
      label: "Apellido",
      rules: [{ required: true, message: 'Por favor ingresa tu apellido' }],
      placeholder: 'Primer Apellido'
    },
    // Dentro de la consola de cognito se crearon 2 campos personalizados (phone_number2 y phone_number) para numeros de telefonos, pero no se estan usando por que son de tipo number y causan un error cuando intentamos agregar un numero superior a la cifra de 2048.
    'custom:NumeroDeTelefono': {
      order: 3,
      label: "Numero de telefono",
      rules: [{ required: true, message: 'Por favor ingresa tu número de celular' }],
      placeholder: '999 000 888'
    },
    'custom:DocumentoDeIdentidad': {
      order: 3,
      label: "Numero de Identidad",
      rules: [{ required: true, message: 'Por favor ingresa tu número de DNI, CE o Pasaporte' }],
      placeholder: 'Favor ingrese solo los dígitos de su documento'
    },
    'custom:Genero': {
      order: 4,
      label: "Genero",
      rules: [{ required: true, message: 'Escoge un genero por favor' }],
      placeholder: 'M ó F'
    },
    birthdate: {
      order: 5
    },
    email: {
      order: 6
    },
    password: {
      order: 7
    },
    confirm_password: {
      order: 8
    }
  },
};

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<GymComponent />} />
        <Route path="/planes" element={<PlanesGym />} />
        <Route path="/clases" element={<ClasesGym />} />
      </Routes>

      <Authenticator components={components} formFields={formFields}>
        <Routes>

          <Route path="/login" element={<Login />} />
          <Route path="/adminCreateClass" element={<AdminCreateClass />} />
          <Route path="/AdminRegisteredCustomers" element={<AdminRegisteredCustomers />} />
          <Route path="/coaches" element={<Coaches />} />

          <Route path="/formulario" element={<Formulario />} />

        </Routes>
      </Authenticator>
    </div>
  );
};

export default App;