/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUsuarios = /* GraphQL */ `
  mutation CreateUsuarios(
    $input: CreateUsuariosInput!
    $condition: ModelUsuariosConditionInput
  ) {
    createUsuarios(input: $input, condition: $condition) {
      email
      nombre
      apellido
      telefono
      documentoDeIdentidad
      birthdate
      fechaDeRegistro
      codigoDeUsuario
      estadoDelUsuario
      generoDelUsuario
      fechaDesde
      fechaHasta
      estatura
      edad
      id
      ultimaAsistencia
      diasDeCongelamiento
      evaluaciones {
        items {
          id
          fechaDeEvaluacion
          peso
          imc
          usuarioEmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUsuarios = /* GraphQL */ `
  mutation UpdateUsuarios(
    $input: UpdateUsuariosInput!
    $condition: ModelUsuariosConditionInput
  ) {
    updateUsuarios(input: $input, condition: $condition) {
      email
      nombre
      apellido
      telefono
      documentoDeIdentidad
      birthdate
      fechaDeRegistro
      codigoDeUsuario
      estadoDelUsuario
      generoDelUsuario
      fechaDesde
      fechaHasta
      estatura
      edad
      id
      ultimaAsistencia
      diasDeCongelamiento
      evaluaciones {
        items {
          id
          fechaDeEvaluacion
          peso
          imc
          usuarioEmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUsuarios = /* GraphQL */ `
  mutation DeleteUsuarios(
    $input: DeleteUsuariosInput!
    $condition: ModelUsuariosConditionInput
  ) {
    deleteUsuarios(input: $input, condition: $condition) {
      email
      nombre
      apellido
      telefono
      documentoDeIdentidad
      birthdate
      fechaDeRegistro
      codigoDeUsuario
      estadoDelUsuario
      generoDelUsuario
      fechaDesde
      fechaHasta
      estatura
      edad
      id
      ultimaAsistencia
      diasDeCongelamiento
      evaluaciones {
        items {
          id
          fechaDeEvaluacion
          peso
          imc
          usuarioEmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEvaluacion = /* GraphQL */ `
  mutation CreateEvaluacion(
    $input: CreateEvaluacionInput!
    $condition: ModelEvaluacionConditionInput
  ) {
    createEvaluacion(input: $input, condition: $condition) {
      id
      fechaDeEvaluacion
      peso
      imc
      usuarioEmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvaluacion = /* GraphQL */ `
  mutation UpdateEvaluacion(
    $input: UpdateEvaluacionInput!
    $condition: ModelEvaluacionConditionInput
  ) {
    updateEvaluacion(input: $input, condition: $condition) {
      id
      fechaDeEvaluacion
      peso
      imc
      usuarioEmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvaluacion = /* GraphQL */ `
  mutation DeleteEvaluacion(
    $input: DeleteEvaluacionInput!
    $condition: ModelEvaluacionConditionInput
  ) {
    deleteEvaluacion(input: $input, condition: $condition) {
      id
      fechaDeEvaluacion
      peso
      imc
      usuarioEmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClases = /* GraphQL */ `
  mutation CreateClases(
    $input: CreateClasesInput!
    $condition: ModelClasesConditionInput
  ) {
    createClases(input: $input, condition: $condition) {
      tipo
      fecha
      cupo
      instructor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClases = /* GraphQL */ `
  mutation UpdateClases(
    $input: UpdateClasesInput!
    $condition: ModelClasesConditionInput
  ) {
    updateClases(input: $input, condition: $condition) {
      tipo
      fecha
      cupo
      instructor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClases = /* GraphQL */ `
  mutation DeleteClases(
    $input: DeleteClasesInput!
    $condition: ModelClasesConditionInput
  ) {
    deleteClases(input: $input, condition: $condition) {
      tipo
      fecha
      cupo
      instructor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInscritos = /* GraphQL */ `
  mutation CreateInscritos(
    $input: CreateInscritosInput!
    $condition: ModelInscritosConditionInput
  ) {
    createInscritos(input: $input, condition: $condition) {
      tipo
      timestamp
      inscrito
      time_clase
      nombre
      apellido
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInscritos = /* GraphQL */ `
  mutation UpdateInscritos(
    $input: UpdateInscritosInput!
    $condition: ModelInscritosConditionInput
  ) {
    updateInscritos(input: $input, condition: $condition) {
      tipo
      timestamp
      inscrito
      time_clase
      nombre
      apellido
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInscritos = /* GraphQL */ `
  mutation DeleteInscritos(
    $input: DeleteInscritosInput!
    $condition: ModelInscritosConditionInput
  ) {
    deleteInscritos(input: $input, condition: $condition) {
      tipo
      timestamp
      inscrito
      time_clase
      nombre
      apellido
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
