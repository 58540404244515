import React from "react";
import { Link } from "react-router-dom";
import FooterDesktop from './FooterDesktop';
import './styles/PlanesGym.css';

const PlanesGym = () => {
  return (
    <div>
      {/* NAVBAR */}
      <div className="py-1 col-sm-12 col-md-12 col-lg-12 col-xl-12 card bg-primary border-0 rounded-0">
        <h5 className="promocion-text"> Obten tu{" "}
          <a className="free" href="https://wa.me/+51924209393/?text=" target="_blank" rel="noreferrer">FREE PASS</a>{" "}y consigue PRECIOS ESPECIALES.&nbsp;
          <span className="blanco"></span>
        </h5>
      </div>

      <div className="d-flex">
        {/* Navbar Superior */}
        <div className="content w-100">
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-5">
            <div className="d-flex align-items-center">
              <Link to="/" className="navbar-brand js-scroll-trigger">
                <img className="imglogo" src="assets/img/logos/Logo para fondo negro.png" alt="" />
              </Link>
              <Link to="/formulario" className="btn btn-primary ml-3">Mi perfil</Link>
            </div>


            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span className="ti-menu text-primary"></span>
            </button>
            <div className=" nav justify-content-end collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link to="/" className="nav-link active js-scroll-trigger">Home</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/planes" className="nav-link active js-scroll-trigger">Planes</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/clases" className="nav-link active js-scroll-trigger">Clases</Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

        </div>
        {/* END Navbar Superior */}

      </div>
      {/* END NAVBAR */}

      <section className="body-planesGym wt-section bg-light" id="services">
        <div className="services-custom container">
          <div className="row justify-content-md-center text-center pb-lg-4 mb-lg-5 mb-4">
            <div className="col-md-8 text-center w-md-50 mx-auto mb-0 ">
              <h2 className=" titulo-anaranjado mb-md-2">Precios de Apertura</h2>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-4 col-lg-2">
                <div className="border-custom card mb-md-0 mb-3 border-1 rounded-md overflow-hidden b-hover" data-aos-easing="linear" data-aos-delay="300">
                  <div className="card-body py-4">
                    <strong href="#" className="planes-letter mb-2 d-block">UN MES</strong>
                    <h5 className="planes-price mb-4 text-primary">S/ 79.00</h5>
                    <div className="planes-letter mb-4">
                      <b>Precio x Mes S/79.00</b>
                    </div>
                    <div className="planes-letter mb-4">
                      <p>PRESENTANDO CARNET UNIVERSITARIO</p>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-4 col-lg-2">
                <div className="border-custom card mb-md-0 mb-3 border-1 rounded-md overflow-hidden b-hover" data-aos-easing="linear" data-aos-delay="300">
                  <div className="card-body py-4">
                    <strong href="#" className="planes-letter mb-2 d-block">UN MES</strong>
                    <h5 className="planes-price mb-4 text-primary">S/ 99.00</h5>
                    <div className="planes-letter mb-4">
                      <b>Precio x Mes S/99.00 </b>
                    </div>
                    <div className="planes-letter mb-3">
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-4 col-lg-2">
                <div className="border-custom card mb-md-0 mb-3 border-1 rounded-md overflow-hidden b-hover" data-aos-delay="300">
                  <div className="card-body py-4">
                    <strong href="#" className="planes-letter mb-2 d-block">3 MESES</strong>
                    <h5 className="planes-price mb-4 text-primary">S/ 249.00</h5>
                    <div className="planes-letter mb-4">
                      <b>Precio x Mes S/83.00 </b>
                    </div>
                    <div className="planes-letter mb-4">
                      <p>+ 15 DIAS DE CONGELAMIENTO </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-4 col-lg-2">
                <div className="border-custom card mb-md-0 mb-3 border-1 rounded-md overflow-hidden b-hover" data-aos-easing="linear" data-aos-delay="300">
                  <div className="card-body py-4">
                    <strong href="#" className="planes-letter mb-2 d-block">6 MESES</strong>
                    <h5 className="planes-price mb-4 text-primary">S/ 349.00</h5>
                    <div className="planes-letter mb-4">
                      <b>Precio x Mes S/58.20 </b>
                    </div>
                    <div className="planes-letter mb-4">
                      <p>+ 20 DIAS DE CONGELAMIENTO </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-4 col-lg-2">
                <div className="border-custom card mb-md-0 mb-3 border-1 rounded-md overflow-hidden b-hover" data-aos-easing="linear" data-aos-delay="500">
                  <div className="card-body py-4">
                    <strong className="planes-letter mb-2 d-block">9 MESES</strong>
                    <h5 className="planes-price mb-4 text-primary">S/ 449.00</h5>
                    <div className="planes-letter mb-4">
                      <b>Precio x Mes S/49.90 </b>
                    </div>
                    <div className="planes-letter mb-4">
                      <p>+ 20 DIAS DE CONGELAMIENTO </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-4 col-lg-2">
                <div className="border-custom card mb-md-0 mb-3 border-1 rounded-md overflow-hidden b-hover" data-aos-easing="linear" data-aos-delay="800">
                  <div className="card-body py-4">
                    <strong href="#" className="planes-letter mb-2 d-block">12 MESES</strong>
                    <h5 className="planes-price mb-4 text-primary">S/ 549.00</h5>
                    <div className="planes-letter mb-4">
                      <b>Precio x Mes S/45.70 </b>
                    </div>
                    <div className="planes-letter mb-4">
                      <p>+ 30 DIAS DE CONGELAMIENTO</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* ... Las demás columnas se encuentran aquí ... */}
            </div>
          </div>



          <div className="container">
            <div className="row justify-content-md-center text-center pb-lg-4 mb-lg-5 mb-4">
              <div className="col-md-8 text-center w-md-50 mx-auto mb-0 ">
                <h2 className=" titulo-anaranjado mb-md-2">Planes Normales</h2>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-4 col-lg-2">
                <div className="border-custom card mb-md-0 mb-3 border-1 rounded-md overflow-hidden b-hover" data-aos-easing="linear" data-aos-delay="300">

                  <div className="card-body py-4">
                    <strong href="#" className="planes-letter mb-2 d-block">UN MES</strong>
                    <h5 className="planes-price mb-4 text-primary">S/ 79.00</h5>
                    <div className="planes-letter mb-4">
                      <b>Precio x Mes S/79.00 </b>
                    </div>
                    <div className="planes-letter mb-4">
                      <p>PRESENTANDO CARNET UNIVERSITARIO</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-2">
                <div className="border-custom card mb-md-0 mb-3 border-1 rounded-md overflow-hidden b-hover" data-aos-easing="linear" data-aos-delay="300">

                  <div className="card-body py-4">
                    <strong href="#" className="planes-letter mb-2 d-block">UN MES</strong>
                    <h5 className="planes-price mb-4 text-primary">S/ 129.00</h5>
                    <div className="planes-letter mb-4">
                      <b>Precio x Mes S/129.00 </b>
                    </div>
                    <div className="planes-letter mb-4">
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-2">
                <div className="border-custom card mb-md-0 mb-3 border-1 rounded-md overflow-hidden b-hover" data-aos-easing="linear" data-aos-delay="300">

                  <div className="card-body py-4">
                    <strong href="#" className="planes-letter mb-2 d-block">3 MESES</strong>
                    <h5 className="planes-price mb-4 text-primary">S/ 399.00</h5>
                    <div className="planes-letter mb-4">
                      <b>Precio x Mes S/133.00 </b>
                    </div>
                    <div className="planes-letter mb-4">
                      <p>+ 15 DIAS DE CONGELAMIENTO </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-2">
                <div className="border-custom card mb-md-0 mb-3 border-1 rounded-md overflow-hidden b-hover" data-aos-easing="linear" data-aos-delay="300">

                  <div className="card-body py-4">
                    <strong href="#" className="planes-letter mb-2 d-block">6 MESES</strong>
                    <h5 className="planes-price mb-4 text-primary">S/ 499.00</h5>
                    <div className="planes-letter mb-4">
                      <b>Precio x Mes S/83.20 </b>
                    </div>
                    <div className="planes-letter mb-4">
                      <p>+ 20 DIAS DE CONGELAMIENTO </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-2">
                <div className="border-custom card mb-md-0 mb-3 border-1 rounded-md overflow-hidden b-hover" data-aos-easing="linear" data-aos-delay="500">

                  <div className="card-body py-4">
                    <strong className="planes-letter mb-2 d-block">9 MESES</strong>
                    <h5 className="planes-price mb-4 text-primary">S/ 599.00</h5>
                    <div className="planes-letter mb-4">
                      <b>Precio x Mes S/66.60 </b>
                    </div>
                    <div className="planes-letter mb-4">
                      <p>+ 20 DIAS DE CONGELAMIENTO </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-2">
                <div className="border-custom card mb-md-0 mb-3 border-1 rounded-md overflow-hidden b-hover" data-aos-easing="linear" data-aos-delay="800">

                  <div className="card-body py-4">
                    <strong href="#" className="planes-letter mb-2 d-block">12 MESES</strong>
                    <h5 className="planes-price mb-4 text-primary">S/ 799.00</h5>
                    <div className="planes-letter mb-4">
                      <b>Precio x Mes S/66.60 </b>
                    </div>
                    <div className="planes-letter mb-4">
                      <p>+ 1 MES GRATIS</p>
                      <p>+ 30 DIAS DE CONGELAMIENTO</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterDesktop />

    </div>
  );
};

export default PlanesGym;
