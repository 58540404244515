import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listUsuarios } from './graphql/queries';
import { updateUsuarios } from './graphql/mutations';
import './styles/AdminAsistencia.css';

const AdminAsistencia = () => {
  const [codigoDeUsuario, setCodigoDeUsuario] = useState('');

  const verificarAsistencia = async () => {
    const hoy = new Date().toISOString().split('T')[0];

    const data = await API.graphql(graphqlOperation(listUsuarios, {
      filter: { codigoDeUsuario: { eq: codigoDeUsuario } }
    }));

    if (data.data.listUsuarios.items.length > 0) {
      const usuario = data.data.listUsuarios.items[0];
      const email = usuario.email;

      const input = {
        email: email,
        ultimaAsistencia: hoy,
      };

      try {
        await API.graphql(graphqlOperation(updateUsuarios, { input: input }));
        alert(`${usuario.nombre} ${usuario.apellido}, Asistencia verificada`);
        setCodigoDeUsuario(''); // Limpiar el input después de cerrar el alert
      } catch (error) {
        console.error('Error al verificar la asistencia: ', error);
      }
    } else {
      alert('No se encontró ningún Socio con ese código');
    }
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      verificarAsistencia();
    }
  };

  return (
    <div className='main-asistencia'>
      <div className='container-asistencia'>
        <h2>Verificar asistencia</h2>
        <form
          className='form-asistencia'
          onSubmit={(e) => {
            e.preventDefault();
            verificarAsistencia();
          }}
        >
          <label className='label-asistencia'>
            Código de usuario:
            <input
              type="text"
              value={codigoDeUsuario}
              onChange={(e) => setCodigoDeUsuario(e.target.value)}
              onKeyPress={handleEnterKey} // Ejecutar la lógica al presionar Enter
            />
          </label>
          <button className='btn btn-primary' type="submit">Verificar</button>
        </form>
      </div>
    </div>
  );
};

export default AdminAsistencia;