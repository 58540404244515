/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUsuarios = /* GraphQL */ `
  query GetUsuarios($email: String!) {
    getUsuarios(email: $email) {
      email
      nombre
      apellido
      telefono
      documentoDeIdentidad
      birthdate
      fechaDeRegistro
      codigoDeUsuario
      estadoDelUsuario
      generoDelUsuario
      fechaDesde
      fechaHasta
      estatura
      edad
      id
      ultimaAsistencia
      diasDeCongelamiento
      evaluaciones {
        items {
          id
          fechaDeEvaluacion
          peso
          imc
          usuarioEmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsuarios = /* GraphQL */ `
  query ListUsuarios(
    $email: String
    $filter: ModelUsuariosFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsuarios(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        nombre
        apellido
        telefono
        documentoDeIdentidad
        birthdate
        fechaDeRegistro
        codigoDeUsuario
        estadoDelUsuario
        generoDelUsuario
        fechaDesde
        fechaHasta
        estatura
        edad
        id
        ultimaAsistencia
        diasDeCongelamiento
        evaluaciones {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvaluacion = /* GraphQL */ `
  query GetEvaluacion($id: ID!) {
    getEvaluacion(id: $id) {
      id
      fechaDeEvaluacion
      peso
      imc
      usuarioEmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvaluacions = /* GraphQL */ `
  query ListEvaluacions(
    $id: ID
    $filter: ModelEvaluacionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEvaluacions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fechaDeEvaluacion
        peso
        imc
        usuarioEmail
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const evaluacionsByUsuarioEmail = /* GraphQL */ `
  query EvaluacionsByUsuarioEmail(
    $usuarioEmail: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEvaluacionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    evaluacionsByUsuarioEmail(
      usuarioEmail: $usuarioEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fechaDeEvaluacion
        peso
        imc
        usuarioEmail
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClases = /* GraphQL */ `
  query GetClases($tipo: String!, $fecha: Float!) {
    getClases(tipo: $tipo, fecha: $fecha) {
      tipo
      fecha
      cupo
      instructor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClases = /* GraphQL */ `
  query ListClases(
    $tipo: String
    $fecha: ModelFloatKeyConditionInput
    $filter: ModelClasesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClases(
      tipo: $tipo
      fecha: $fecha
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tipo
        fecha
        cupo
        instructor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInscritos = /* GraphQL */ `
  query GetInscritos($tipo: String!, $timestamp: Float!) {
    getInscritos(tipo: $tipo, timestamp: $timestamp) {
      tipo
      timestamp
      inscrito
      time_clase
      nombre
      apellido
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInscritos = /* GraphQL */ `
  query ListInscritos(
    $tipo: String
    $timestamp: ModelFloatKeyConditionInput
    $filter: ModelInscritosFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInscritos(
      tipo: $tipo
      timestamp: $timestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tipo
        timestamp
        inscrito
        time_clase
        nombre
        apellido
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
