import React from 'react';
import './styles/FooterDesktop.css';

function FooterDesktop() {
	return (
		<footer className="footer-desktop bg-dark py-5" id="footer">
			<div className="container">
				<div className="row">
					<div className="col-md-6 align-self-center">
						<div className="wow slideInRight" data-wow-delay="0s" style={{ visibility: "visible", animationDelay: "0s", animationName: "slideInRight" }}>
							<ul className="footer-contacts d-inline-block d-sm-block">
								<h6 style={{ color: "#FF9900" }} className="text-uppercase mb-5">Contáctanos</h6>
								<li>
									<div className="unit mb-3">
										{/*<div className="unit-left"><span className="icon fa fa-phone"></span></div>*/}
										<div className="unit-body"><a className="link-phone" href="tel:#">+51 924 209 393</a></div>
									</div>
								</li>
								<li>
									<div className="unit mb-3">
										{/*<div className="unit-left"><span className="icon fa fa-phone"></span></div>*/}
										<div className="unit-body"><a className="link-email" href="mailto:#">ventas@beatforceperu.com</a></div>
									</div>
								</li>
								<li>
									<div className="unit mb-3">
										{/*<div className="unit-left"><span className="icon fa fa-phone"></span></div>*/}
										<div className="unit-body"><a className="link-location" href="/" rel='noreferrer'>Jirón José Gálvez 670 – Magdalena del Mar</a></div>
									</div>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-md-6 align-self-center">
						<div className="zona hovereffect rounded-md overflow-hidden">
							<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1950.6636718219427!2d-77.0751453250479!3d-12.089728289431907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c9a0d1181011%3A0x881c88174e08d599!2sJir%C3%B3n%20Jose%20Galvez%20670%2C%20Lima%2015086!5e0!3m2!1ses!2spe!4v1666712771081!5m2!1ses!2spe" title='maps' width="550" height="300" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-8 text-center text-md-left mb-3 mb-md-0">
						<small className="text-white"><a className="text-white" href="https://webthemez.com/free-bootstrap-templates/">Enlace a los templates gratuitos de Bootstrap</a><br />&copy; Todos los derechos reservados.</small>
					</div>

					<div className="col-md-4 align-self-center">
						<ul className="list-inline text-center text-md-right mb-0">
							<li className="list-inline-item mx-2" data-toggle="tooltip" data-placement="top" title="Facebook">
								<a className="text-white" target="_blank" href="https://www.facebook.com/beatforceperu" rel='noreferrer'>
									<i className="fab fa-facebook"></i>
								</a>
							</li>
							<li className="list-inline-item mx-2" data-toggle="tooltip" data-placement="top" title="Instagram">
								<a className="text-white" target="_blank" href="https://www.instagram.com/beatforceperu/" rel='noreferrer'>
									<i className="fab fa-instagram"></i>
								</a>
							</li>
							<li className="list-inline-item mx-2" data-toggle="tooltip" data-placement="top" title="Tiktok">
								<a href="https://www.tiktok.com/@beatforceperu" target="_blank" rel='noreferrer'>
									<img className="icon-tiktok" src="assets/img/svg/tiktok.svg" width="40" height="40" alt="TikTok" />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default FooterDesktop