import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getUsuarios } from './graphql/queries';

import AdminCreateClass from './AdminCreateClass';
import AdminRegisteredCustomers from './AdminRegisteredCustomers';
import AdminAsistencia from './AdminAsistencia';
import './styles/Login.css';
// import NotificacionesAdmin from './NotificacionesAdmin';


const Home = () => {
	const { signOut } = useAuthenticator();
	const navigate = useNavigate();

	const handleSignOut = async () => {
		try {
			await signOut();
			navigate('/');
		} catch (error) {
			console.error('Error al cerrar sesión:', error);
		}
	};

	return (
		<button id="cerrar-sesion" className="admin-signOut list-group-item list-group-item-action bg-dark text-light" onClick={handleSignOut}>
			<i className="ti-power-off"></i>
		</button>
	);
};

const AdminGym = () => {
	const [activeComponent, setActiveComponent] = useState('asistencia');

	const handleButtonClick = (componentName) => {
		setActiveComponent(componentName);
	};

	return (
		<div className="auth-container">
			<div className="d-flex">
				{/* Navbar */}
				<div className="content w-100">

					<nav className="navbar navbar-expand-lg navbar-dark bg-dark pb-0">
						{/* Logo y Bienvenida */}
						<div className="d-flex align-items-center">
							<Link to="/" className="navbar-brand js-scroll-trigger ml-3 mb-3">
								<img className="imglogo" src="assets/img/logos/Logo para fondo negro.png" alt="" />
							</Link>
							<h6 className='text-white'>Bienvenido Admin</h6>
						</div>
						{/* End Logo y Bienvenida */}


						{/* Menu Central */}
						<button className="navbar-toggler ml-auto mr-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span className="ti-menu text-primary"></span>
						</button>

						<div className="collapse navbar-collapse" id="navbarSupportedContent">
							<div className="navbar-nav ml-auto mr-auto">
								<button className='btn btn-primary ml-3' onClick={() => handleButtonClick('createClass')}>Crear Clase</button>
								<button className='btn btn-primary ml-3' onClick={() => handleButtonClick('registeredCustomers')}>Clientes Registrados</button>
								<button className='btn btn-primary ml-3' onClick={() => handleButtonClick('asistencia')}>Asistencia</button>
							</div>
							{/* End Menu Central */}


							{/* Menu "PERFIL" */}
							<div className="form-inline my-2 my-lg-0">
								{/* <div className="mx-1 dropdown">
									<button className="admin-notificacion-icon btn border-primary text-light" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<i className="ti-bell"></i>
									</button>
									<div className="admin-notificacione-text p-0 dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton2" style={{ left: 'auto', right: 0 }}>
										<NotificacionesAdmin />
									</div>
								</div> */}

								<div >
									<div className="btn border-primary">
										<Home />
									</div>
								</div>
							</div>
							{/* End Menu "PERFIL" */}
						</div>
					</nav>
				</div>
			</div>
			{/* Renderiza el componente activo según el estado */}
			{activeComponent === 'createClass' && <AdminCreateClass />}
			{activeComponent === 'registeredCustomers' && <AdminRegisteredCustomers />}
			{activeComponent === 'asistencia' && <AdminAsistencia />}
		</div>
	);
};

const Login = () => {
	const { loading } = useAuthenticator();
	const navigate = useNavigate();
	const [showAdminGym, setShowAdminGym] = useState(false);

	useEffect(() => {
		const checkUser = async () => {
			try {
				if (!loading) {
					const user = await Auth.currentAuthenticatedUser();
					const groups = user.signInUserSession.idToken.payload['cognito:groups'];

					if (groups && groups.includes('Admin')) {
						// Si el usuario pertenece al grupo 'Admin', muestra el componente AdminGym
						setShowAdminGym(true);
					} else {
						// Si el usuario no pertenece al grupo 'Admin', verifica el estadoDelUsuario
						const userDataResponse = await API.graphql(graphqlOperation(getUsuarios, { email: user.attributes.email }));
						const userData = userDataResponse.data.getUsuarios;

						// Verificar si userData es nulo antes de acceder a 'estadoDelUsuario'
						const estadoDelUsuario = userData ? userData.estadoDelUsuario : null;

						// Verificar estadoDelUsuario y redirigir según sea necesario
						if (estadoDelUsuario === 'Coach') {
							// Redirigir a la interfaz de Coaches.js
							navigate('/coaches');
							console.log('Usuario Coach');
						} else {
							// Redirigir a la interfaz de Formulario.js
							navigate('/formulario');
						}
					}
				}
			} catch (error) {
				console.error('Error al obtener el usuario autenticado:', error);
				setShowAdminGym(false);
			}
		};

		checkUser();
	}, [loading, navigate]);

	return (
		<Authenticator>
			{showAdminGym ? <AdminGym /> : null}
		</Authenticator>
	);
};

export default Login;