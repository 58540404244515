import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createClases } from './graphql/mutations';
import { listClases } from './graphql/queries';
import ClasesCreadasDeCycling from './ClasesCreadasDeCycling';
import ClasesCreadasDeBaile from './ClasesCreadasDeBaile';
import ClasesCreadasDeFuncional from './ClasesCreadasDeFuncional';
import ClasesCreadasDeFullBody from './ClasesCreadasDeFullBody';
import ClasesCreadasDeExLocal from './ClasesCreadasDeExLocal';
import ClasesCreadasDeXBox from './ClasesCreadasDeXBox';


const AdminCreateClass = () => {
    const [clases, setClases] = useState([]);
    const [newClass, setNewClass] = useState({
        tipo: '',
        fecha: '',
        cupo: 0,
        instructor: '',
    });


    // Define clasesPorTipo
    const clasesPorTipo = {
        cycling: [],
        baile: [],
        funcional: [],
        fullbody: [],
        ExLocal: [],
        XBox: [],
    };

    // Función para crear una nueva clase
    const createNewClass = async () => {
        try {
            // Formatea la fecha como un timestamp (en milisegundos)
            const formattedDate = Math.floor(new Date(newClass.fecha).getTime() / 1000);
            newClass.fecha = formattedDate; // Actualiza la propiedad fecha en el objeto newClass

            // Obtiene el valor del campo de entrada "tipo" en el formulario
            newClass.tipo = document.getElementById('tipo').value;

            // Realiza la llamada a la API para crear una nueva clase
            await API.graphql(graphqlOperation(createClases, { input: newClass }));

            // Actualiza la lista de clases
            fetchClasses();

            //         // Limpia el formulario
            setNewClass({
                tipo: '',
                fecha: '',
                cupo: 0,
                instructor: '',
            });
            // Después de crear una nueva clase con éxito
            setCreatedClassData({
                tipo: newClass.tipo,
                fecha: newClass.fecha,
                cupo: newClass.cupo,
                instructor: newClass.instructor,
            });
            // Abre el modal
            setIsModalOpen(true);

        } catch (error) {
            console.error('Error al crear una nueva clase:', error);
        }
    };



    // Función para obtener la lista de clases
    const fetchClasses = async () => {
        try {
            // Realiza la llamada a la API para obtener la lista de clases
            const response = await API.graphql(graphqlOperation(listClases));
            const clasesData = response.data.listClases.items;

            // Organiza las clases en clasesPorTipo
            clasesData.forEach((clase) => {
                if (clasesPorTipo[clase.tipo]) {
                    clasesPorTipo[clase.tipo].push(clase);
                }
            });

            // Actualiza el estado con las clases
            setClases(clasesData);
        } catch (error) {
            console.error('Error al obtener la lista de clases:', error);
        }
    };


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [createdClassData, setCreatedClassData] = useState(null);


    useEffect(() => {
        fetchClasses();
    }, []);

    return (
        <>
            {/* Formulario siempre visible */}
            <div className="card text-center">
                <div className="card-header">
                    <h2>Crear Nueva Clase</h2>
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-group row align-items-center">
                            <label htmlFor="tipo" className="col-sm-4 col-form-label text-sm-right">Tipo de Clase:</label>
                            <div className="col-sm-6">
                                <select
                                    id="tipo"
                                    name="tipo"
                                    className="form-control"
                                    value={newClass.tipo}
                                    onChange={(e) => setNewClass({ ...newClass, tipo: e.target.value })}
                                >
                                    <option value="" disabled hidden>Seleccione el tipo de clase</option>
                                    <option value="cycling">Cycling</option>
                                    <option value="baile">Baile</option>
                                    <option value="funcional">Funcional</option>
                                    <option value="fullbody">Full Body</option>
                                    <option value="Ex-Local">Ex-Local</option>
                                    <option value="X-Box">X-Box</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row align-items-center">
                            <label htmlFor="fecha" className="col-sm-4 col-form-label text-sm-right">Fecha y Hora:</label>
                            <div className="col-sm-6">
                                <input
                                    type="datetime-local"
                                    id="fecha"
                                    name="fecha"
                                    className="form-control"
                                    value={newClass.fecha}
                                    onChange={(e) => setNewClass({ ...newClass, fecha: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="form-group row align-items-center">
                            <label htmlFor="cupo" className="col-sm-4 col-form-label text-sm-right">Cupos Disponibles:</label>
                            <div className="col-sm-6">
                                <input
                                    type="number"
                                    id="cupo"
                                    name="cupo"
                                    className="form-control"
                                    value={newClass.cupo}
                                    onChange={(e) => setNewClass({ ...newClass, cupo: parseInt(e.target.value) })}
                                    placeholder="Ingrese la cantidad de cupos"
                                />
                            </div>
                        </div>
                        <div className="form-group row align-items-center">
                            <label htmlFor="instructor" className="col-sm-4 col-form-label text-sm-right">Instructor:</label>
                            <div className="col-sm-6">
                                <input
                                    type="text"
                                    id="instructor"
                                    name="instructor"
                                    className="form-control"
                                    value={newClass.instructor}
                                    onChange={(e) => setNewClass({ ...newClass, instructor: e.target.value })}
                                    placeholder="Instructor"
                                />
                            </div>
                        </div>


                        <button type="button" className="btn btn-primary" onClick={createNewClass}>
                            Crear Clase
                        </button>
                    </form>
                </div>
                <div className="card-footer text-muted">
                    Beat Force Perú
                </div>
            </div>

            {/* Lista de clases creadas */}
            <div className="accordion" id="accordionExample">
                <div className="card">
                    <div className="card-header" id="headingCycling">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseCycling" aria-expanded="true" aria-controls="collapseCycling">
                                Clases de Cycling
                            </button>
                        </h2>
                    </div>
                    <div id="collapseCycling" className="collapse" aria-labelledby="headingCycling" data-parent="#accordionExample">
                        <div className="card-body overflow-auto" style={{ maxHeight: '300px' }}>
                            <ClasesCreadasDeCycling tipo="cycling" clasesPorTipo={clasesPorTipo} />
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header" id="headingBaile">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseBaile" aria-expanded="false" aria-controls="collapseBaile">
                                Clases de Baile
                            </button>
                        </h2>
                    </div>
                    <div id="collapseBaile" className="collapse" aria-labelledby="headingBaile" data-parent="#accordionExample">
                        <div className="card-body overflow-auto" style={{ maxHeight: '300px' }}>
                            <ClasesCreadasDeBaile tipo="baile" clasesPorTipo={clasesPorTipo} />
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header" id="headingFuncional">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFuncional" aria-expanded="false" aria-controls="collapseFuncional">
                                Clases de Funcional
                            </button>
                        </h2>
                    </div>
                    <div id="collapseFuncional" className="collapse" aria-labelledby="headingFuncional" data-parent="#accordionExample">
                        <div className="card-body overflow-auto" style={{ maxHeight: '300px' }}>
                            <ClasesCreadasDeFuncional tipo="funcional" clasesPorTipo={clasesPorTipo} />
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header" id="headingFullBody">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFullBody" aria-expanded="false" aria-controls="collapseFullBody">
                                Clases de Full Body
                            </button>
                        </h2>
                    </div>
                    <div id="collapseFullBody" className="collapse" aria-labelledby="headingFullBody" data-parent="#accordionExample">
                        <div className="card-body overflow-auto" style={{ maxHeight: '300px' }}>
                            <ClasesCreadasDeFullBody tipo="fullbody" clasesPorTipo={clasesPorTipo} />
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header" id="headingExLocal">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseExLocal" aria-expanded="false" aria-controls="collapseExLocal">
                                Clases de Ex-Local
                            </button>
                        </h2>
                    </div>
                    <div id="collapseExLocal" className="collapse" aria-labelledby="headingExLocal" data-parent="#accordionExample">
                        <div className="card-body overflow-auto" style={{ maxHeight: '300px' }}>
                            <ClasesCreadasDeExLocal tipo="Ex-Local" clasesPorTipo={clasesPorTipo} />
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header" id="headingXBox">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseXBox" aria-expanded="false" aria-controls="collapseXBox">
                                Clases de X-Box
                            </button>
                        </h2>
                    </div>
                    <div id="collapseXBox" className="collapse" aria-labelledby="headingXBox" data-parent="#accordionExample">
                        <div className="card-body overflow-auto" style={{ maxHeight: '300px' }}>
                            <ClasesCreadasDeXBox tipo="X-Box" clasesPorTipo={clasesPorTipo} />
                        </div>
                    </div>
                </div>
            </div>
            {/* end Lista de clases creadas */}

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Clase Creada</h3>
                        {createdClassData && (
                            <div>
                                <p>Tipo de Clase: {createdClassData.tipo}</p>
                                <p>Fecha y Hora: {new Date(createdClassData.fecha * 1000).toLocaleString()}</p>
                                <p>Cupo Disponible: {createdClassData.cupo}</p>
                                <p>Instructor: {createdClassData.instructor}</p>
                            </div>
                        )}
                        <button onClick={() => setIsModalOpen(false)}>Cerrar</button>
                    </div>
                </div>
            )
            }
        </>
    )
}

export default AdminCreateClass