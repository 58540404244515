import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listUsuarios } from './graphql/queries';
import { updateUsuarios } from './graphql/mutations';
import './styles/AdminRegisteredCustomers.css';


function AdminRegisteredCustomers() {
  const [userList, setUserList] = useState([]);
  const [orden, setOrden] = useState('asc');
  const [filtroMembresia, setFiltroMembresia] = useState('todos');
  const [busqueda, setBusqueda] = useState('');
  const [filtroGenero, setFiltroGenero] = useState('todos');
  const [buttonEnabledStatus, setButtonEnabledStatus] = useState({});

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const userData = await API.graphql(graphqlOperation(listUsuarios));
        let users = userData.data.listUsuarios.items;

        // Aplicar filtro por membresía si está seleccionado
        if (filtroMembresia !== 'todos') {
          users = users.filter(user => user.estadoDelUsuario === filtroMembresia);
        }

        // Aplicar filtro por género si está seleccionado
        if (filtroGenero !== 'todos') {
          users = users.filter(user => {
            const generoUsuario = user.generoDelUsuario ? user.generoDelUsuario.toLowerCase() : null;
            return generoUsuario === filtroGenero || (generoUsuario && generoUsuario.startsWith(filtroGenero));
          });
        }



        // Aplicar filtro de búsqueda
        if (busqueda.trim() !== '') {
          const terminoBusqueda = busqueda.toLowerCase();
          users = users.filter(
            user =>
              user.nombre.toLowerCase().includes(terminoBusqueda) ||
              user.apellido.toLowerCase().includes(terminoBusqueda) ||
              user.email.toLowerCase().includes(terminoBusqueda) ||
              (user.codigoDeUsuario && user.codigoDeUsuario.toLowerCase().includes(terminoBusqueda))
          );
        }

        // Ordenar localmente según el criterio seleccionado
        users = users.sort((a, b) => {
          if (orden === 'asc') {
            return a.fechaDeRegistro - b.fechaDeRegistro;
          } else if (orden === 'desc') {
            return b.fechaDeRegistro - a.fechaDeRegistro;
          }
          return 0;
        });

        setUserList(users);
      } catch (error) {
        console.error('Error al obtener la lista de usuarios:', error);
      }
    };

    fetchUserList();
  }, [orden, filtroMembresia, busqueda, filtroGenero]);

  const handleOrdenChange = (e) => {
    setOrden(e.target.value);
  };

  const handleFiltroMembresiaChange = (e) => {
    setFiltroMembresia(e.target.value);
  };

  const handleBusquedaChange = (e) => {
    setBusqueda(e.target.value);
  };

  const enableButtonForUser = (email) => {
    setButtonEnabledStatus(prevStatus => ({ ...prevStatus, [email]: true }));
  };

  const disableButtonForUser = (email) => {
    setButtonEnabledStatus(prevStatus => ({ ...prevStatus, [email]: false }));
  };

  const handleStatusChange = (email, newStatus) => {
    console.log(`Email: ${email}, Nuevo Estado: ${newStatus}`);
    const updatedUserList = userList.map(user => {
      if (user.email === email) {
        return { ...user, estadoDelUsuario: newStatus };
      }
      return user;
    });
    setUserList(updatedUserList);
    enableButtonForUser(email);
  };

  const handleDateChange = (email, dateField, date) => {
    console.log(`Email: ${email}, ${dateField}: ${date}`);
    const updatedUserList = userList.map(user => {
      if (user.email === email) {
        return { ...user, [dateField]: date };
      }
      return user;
    });
    setUserList(updatedUserList);
    enableButtonForUser(email);
  };

  const handleCodigoChange = (email, codigo) => {
    console.log(`Email: ${email}, Código: ${codigo}`);
    const updatedUserList = userList.map(user => {
      if (user.email === email) {
        return { ...user, codigoDeUsuario: codigo };
      }
      return user;
    });
    setUserList(updatedUserList);
    enableButtonForUser(email);
  };

  const registrardiasDeCongelamiento = (email, diasDeCongelamiento) => {
    console.log(`Email: ${email}, Código: ${diasDeCongelamiento}`);
    const updatedUserList = userList.map(user => {
      if (user.email === email) {
        return { ...user, diasDeCongelamiento: diasDeCongelamiento };
      }
      return user;
    });
    setUserList(updatedUserList);
    enableButtonForUser(email);
  };


  const handleButtonClick = async (email) => {
    console.log('Botón OK clicado');
    disableButtonForUser(email);

    const user = userList.find(user => user.email === email);
    if (!user) {
      console.error('No se encontró el usuario:', email);
      return;
    }

    // Verificar longitud mínima del código
    if (user.codigoDeUsuario.length < 3) {
      alert('El código debe ser de mínimo 3 dígitos');
      console.error('El código debe ser de mínimo 3 dígitos');
      // Puedes mostrar un mensaje al usuario indicando el error
      return;
    }

    // Verificar si el código ya está asignado a otro usuario
    const codigoExists = userList.some(u => u.codigoDeUsuario === user.codigoDeUsuario && u.email !== email);
    if (codigoExists) {
      const nextAvailableCodigo = getNextAvailableCodigo(userList, user.codigoDeUsuario);
      alert(`Este código ya fue asignado a otro socio, te sugiero asignar el código ${nextAvailableCodigo}`);
      console.error(`Este código ya fue asignado a otro socio, te sugiero asignar el código ${nextAvailableCodigo}`);
      // Puedes mostrar un mensaje al usuario indicando el error
      return;
    }

    if (user.estadoDelUsuario === null || user.fechaDesde === null || user.fechaHasta === null) {
      console.error('Uno de los campos a actualizar es null:', user);
      return;
    }

    try {
      await API.graphql(graphqlOperation(updateUsuarios, {
        input: {
          email: user.email,
          estadoDelUsuario: user.estadoDelUsuario,
          fechaDesde: user.fechaDesde,
          fechaHasta: user.fechaHasta,
          codigoDeUsuario: user.codigoDeUsuario,
          diasDeCongelamiento: user.diasDeCongelamiento,
        },
      }));
      alert(`Socio ${user.nombre} ${user.apellido} actualizado con éxito`);
      console.log('Usuario actualizado con éxito:', email);
    } catch (error) {
      console.error('Error al actualizar el usuario:', error);
    }
  };

  // Función para obtener el siguiente código disponible
  const getNextAvailableCodigo = (userList, currentCodigo) => {
    const existingCodigos = userList.map(u => u.codigoDeUsuario);
    const numericCodigos = existingCodigos.map(c => parseInt(c, 10)).filter(c => !isNaN(c));
    const maxCodigo = Math.max(...numericCodigos, 0);

    // Asumiendo que los códigos son numéricos, puedes ajustar esto según tu implementación
    const nextAvailableCodigo = (maxCodigo + 1).toString().padStart(currentCodigo.length, '0');
    return nextAvailableCodigo;
  };

  return (
    <>
      <div className='container-titulo-filtros card-header'>
        <h2>Socios Registrados</h2>

        <div className='filtros'>
          <section>
            <p>Buscar por:</p>
            <input
              type="text"
              placeholder="Nombre, email, código de usuario"
              value={busqueda}
              onChange={handleBusquedaChange} />
          </section>

          <section>
            <p>Género:</p>
            <select value={filtroGenero} onChange={(e) => setFiltroGenero(e.target.value.toLowerCase())}>
              <option value="todos">Todos</option>
              <option value="m">Masculino</option>
              <option value="f">Femenino</option>
            </select>
          </section>


          <section>
            <p>Ordenar por:</p>
            <select value={orden} onChange={handleOrdenChange}>
              <option value="asc">Mas nuevos</option>
              <option value="desc">Mas antiguos</option>
            </select>
          </section>

          <section>
            <p>Tipo de membresia:</p>
            <select value={filtroMembresia} onChange={handleFiltroMembresiaChange}>
              <option value="todos">Todos</option>
              <option value="Cliente Nuevo">Clientes Nuevos</option>
              <option value="Socio">Socio</option>
              {/* <option value="Coach">Coach</option> */}
            </select>
          </section>
        </div>

      </div>

      <table className="table">

        <thead>
          <tr>
            <th>N°</th>
            <th>Socio</th>
            <th>Codigo</th>
            <th>Género</th>
            <th>Edad</th>
            <th>Teléfono</th>
            {/* <th>Cumpleaños</th> */}
            {/* <th>DNI</th> */}
            {/* <th>Email</th> */}
            <th>Dias-Congelamiento</th>
            <th>Ultima Asistencia</th>
            {/* <th>Fecha de Registro</th> */}
            <th>Membresia</th>
            <th>Inicio Membresia</th>
            <th>Fin Membresia</th>
          </tr>
        </thead>

        <tbody>
          {userList.map((user) => (
            <tr key={user.email}>
              <td></td>
              <td>{user.nombre} {user.apellido}</td>
              <td className='codegoUsuario-contenido'>
                <input
                  type="text"
                  value={user.codigoDeUsuario}
                  onChange={(e) => handleCodigoChange(user.email, e.target.value)}
                />
              </td>
              <td>{user.generoDelUsuario}</td>
              <td>{user.edad}</td>
              <td>{user.telefono}</td>
              {/* <td>{user.birthdate}</td> */}
              {/* <td>{user.documentoDeIdentidad}</td> */}
              {/* <td>{user.email}</td> */}
              <td className='diasDeCongelamiento-contenido'>
                <input
                  type="text"
                  value={user.diasDeCongelamiento}
                  onChange={(e) => registrardiasDeCongelamiento(user.email, e.target.value)}
                />
              </td>
              <td>{user.ultimaAsistencia}</td>
              {/* <td>{user.fechaDeRegistro ? new Date(user.fechaDeRegistro * 1000).toLocaleString() : ''}</td> */}
              <td>
                <select value={user.estadoDelUsuario} onChange={(e) => { handleStatusChange(user.email, e.target.value); }}>
                  <option value={user.estadoDelUsuario}>{user.estadoDelUsuario}</option>
                  <option value={user.estadoDelUsuario === 'Cliente Nuevo' ? 'Socio' : 'Cliente Nuevo'}>
                    {user.estadoDelUsuario === 'Cliente Nuevo' ? 'Socio' : 'Cliente Nuevo'}
                  </option>
                  {/* <option value={user.estadoDelUsuario === 'Coach' ? 'Free' : 'Coach'}>
                    {user.estadoDelUsuario === 'Coach' ? 'Free' : 'Coach'}
                  </option> */}
                </select>
              </td>
              <td>
                <input
                  type="date"
                  value={user.fechaDesde ? user.fechaDesde.split('T')[0] : ''}
                  onChange={(e) => handleDateChange(user.email, 'fechaDesde', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="date"
                  value={user.fechaHasta ? user.fechaHasta.split('T')[0] : ''}
                  onChange={(e) => handleDateChange(user.email, 'fechaHasta', e.target.value)}
                />
              </td>
              <td>
                <button onClick={() => handleButtonClick(user.email)} disabled={!buttonEnabledStatus[user.email]}>
                  OK
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default AdminRegisteredCustomers;