import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { listUsuarios } from './graphql/queries';
import '@aws-amplify/ui-react/styles.css';
import { useAuthenticator } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import { evaluacionsByUsuarioEmail } from './graphql/queries';
import './styles/Coaces.css'

Amplify.configure(awsExports);

const Home = () => {
	const { signOut } = useAuthenticator();
	const navigate = useNavigate();

	const handleSignOut = async () => {
		try {
			await signOut();
			navigate('/');
		} catch (error) {
			console.error('Error al cerrar sesión:', error);
		}
	};

	return (
		<button
			id="cerrar-sesion"
			className="list-group-item list-group-item-action bg-dark text-light pl-5"
			onClick={handleSignOut}
		>
			Cerrar Sesión
		</button>
	);
};

async function fetchUsuarios() {
	try {
		const usuariosData = await API.graphql(graphqlOperation(listUsuarios));
		return usuariosData.data.listUsuarios.items;
	} catch (err) {
		console.error('Error al obtener usuarios:', err);
	}
}

function Coaches() {
	const { user } = useAuthenticator();
	const [usuarios, setUsuarios] = React.useState([]);
	const [selectedUserForNewEvaluation, setSelectedUserForNewEvaluation] = useState(null);
	const [selectedUserForConsultData, setSelectedUserForConsultData] = useState(null);
	const [showNewEvaluationModal, setShowNewEvaluationModal] = useState(false);
	const [showConsultDataModal, setShowConsultDataModal] = useState(false);
	const [userConsultDataEvaluations, setUserConsultDataEvaluations] = useState([]);

	const customNombre = user?.attributes?.['custom:Nombre'];
	const customApellido = user?.attributes?.['custom:Apellido'];

	React.useEffect(() => {
		fetchUsuarios().then(setUsuarios);
	}, []);

	// Maneja la apertura del modal y almacena el usuario seleccionado
	const handleNuevaEvaluacionClick = async (usuario) => {
		setSelectedUserForNewEvaluation({ ...usuario });
		setShowNewEvaluationModal(true);

		try {
			const response = await API.graphql(graphqlOperation(evaluacionsByUsuarioEmail, { usuarioEmail: usuario.email }));
			setUserConsultDataEvaluations(response.data.evaluacionsByUsuarioEmail.items);
		} catch (error) {
			console.error('Error al obtener las evaluaciones del usuario para consultar datos:', error);
		}
	};

	// Función para manejar la consulta de datos
	const handleConsultarDatosClick = async (usuario) => {
		setSelectedUserForConsultData({ ...usuario });
		setShowConsultDataModal(true);

		try {
			const response = await API.graphql(graphqlOperation(evaluacionsByUsuarioEmail, { usuarioEmail: usuario.email }));
			const sortedEvaluations = response.data.evaluacionsByUsuarioEmail.items.sort(
				(a, b) => new Date(b.fechaDeEvaluacion) - new Date(a.fechaDeEvaluacion)
			);
			setUserConsultDataEvaluations(sortedEvaluations);
		} catch (error) {
			console.error('Error al obtener las evaluaciones del usuario para consultar datos:', error);
		}
	};

	// función para limpiar los datos del estado al cerrar el modal
	const handleModalClose = () => {
		setShowConsultDataModal(false);
		// Limpiar los datos del estado
		setSelectedUserForConsultData(null);
		setUserConsultDataEvaluations([]);
	};

	// useEffect para limpiar los datos al desmontar el componente
	React.useEffect(() => {
		return () => {
			// Limpiar los datos del estado al desmontar el componente
			setSelectedUserForConsultData(null);
			setUserConsultDataEvaluations([]);
		};
	}, []);

	const resetModal = () => {
		document.getElementById('peso').value = '';
		document.getElementById('igc').value = '';
		// Puedes agregar más campos y reiniciar sus valores si es necesario
	};

	const handleGuardarEvaluacion = () => {
		const fechaActual = new Date().toISOString().split('T')[0]; // Obtiene solo la fecha, sin la hora
		const datosEvaluacion = {
			peso: document.getElementById('peso').value,
			igc: document.getElementById('igc').value,
			fechaDeEvaluacion: fechaActual,
			usuarioEmail: selectedUserForNewEvaluation.email,
		};

		// Primero se verifica si ya existe una evaluación para este usuario en esta fecha
		API.graphql(graphqlOperation(evaluacionsByUsuarioEmail, { usuarioEmail: selectedUserForNewEvaluation.email }))
			.then((response) => {
				const evaluacionesDelDia = response.data.evaluacionsByUsuarioEmail.items.filter(
					(evaluacion) => evaluacion.fechaDeEvaluacion.split('T')[0] === fechaActual
				);
				if (evaluacionesDelDia.length > 0) {
					console.log('Ya existe una evaluación para este usuario en esta fecha');
					alert('Ya existe una evaluación para este usuario en esta fecha');
				} else {
					// Si no existe una evaluación previa, proceder a crear la nueva evaluación
					API.graphql(graphqlOperation(createEvaluacion, { input: datosEvaluacion }))
						.then((response) => {
							console.log('Evaluación guardada con éxito', response);
							alert('Evaluación guardada con éxito');
							setShowNewEvaluationModal(false);
							resetModal();
						})
						.catch((error) => {
							console.error('Error al guardar la evaluación', error);
						});
				}
			})
			.catch((error) => {
				console.error('Error al verificar la existencia de una evaluación', error);
			});
	};

	const createEvaluacion = /* GraphQL */ `
    mutation CreateEvaluacion($input: CreateEvaluacionInput!) {
      createEvaluacion(input: $input) {
        id
        fechaDeEvaluacion
        peso
        igc
        usuarioEmail
      }
    }
  `;


	// Falta agregar estatura, edad
	return (
		<>
			{/* Navbar */}
			<nav className="navbar navbar-expand-lg navbar-dark bg-dark pb-0">
				{/* Logo y Bienvenida */}
				<div className="d-flex align-items-center">
					<Link to="/" className="navbar-brand js-scroll-trigger ml-3 mb-3">
						<img className="imglogo" src="assets/img/logos/Logo para fondo negro.png" alt="" />
					</Link>
					<h6 className="text-white">Bienvenido {customNombre} {customApellido}</h6>
				</div>
				{/* End Logo y Bienvenida */}

				{/* Menu Central */}
				<button
					className="navbar-toggler ml-auto mr-2"
					type="button"
					data-toggle="collapse"
					data-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="ti-menu text-primary"></span>
				</button>

				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav ml-auto mr-auto">
						<li className="nav-item">
							<Link to="/" className="nav-link active js-scroll-trigger">
								Home
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/planes" className="nav-link active js-scroll-trigger">
								Planes
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/clases" className="nav-link active js-scroll-trigger">
								Clases
							</Link>
						</li>
					</ul>
					<div className="form-inline my-2 my-lg-0">
						{/* End Menu Central */}

						{/* Menu "Reservas" */}
						<div className="mx-1 dropdown">{/* hola */}</div>
						{/* End Menu "Reservas" */}

						{/* Menu "PERFIL" */}
						<div className="mx-1 dropdown">
							<button
								className="btn border-primary text-light dropdown-toggle"
								type="button"
								id="dropdownMenuButton2"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<i className="fa fa-user mr-3" aria-hidden="true"></i>
							</button>
							<div className="p-0 dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton2" style={{ left: 'auto', right: 0 }}>
								<Home />
							</div>
						</div>
						{/* End Menu "PERFIL" */}
					</div>
				</div>
			</nav>
			{/* End Navbar */}

			<div>
				<h5>Lista de clientes registrados</h5>
				<table>
					<thead>
						<tr>
							<th>Nombre y Apellido</th>
						</tr>
					</thead>
					<tbody>
						{usuarios.map((usuario) => (
							<tr key={usuario.email}>
								<td>
									{usuario.nombre} {usuario.apellido}
								</td>
								<td>
									<button onClick={() => handleConsultarDatosClick(usuario)}>Consultar Datos</button>
									<button onClick={() => handleNuevaEvaluacionClick(usuario)}>Nueva Evaluacion</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>


			{/* Nuevo: Modal para nueva evaluación */}
			{selectedUserForNewEvaluation && (
				<div className={`modal ${showNewEvaluationModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showNewEvaluationModal ? 'block' : 'none' }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Nueva Evaluación - {selectedUserForNewEvaluation.nombre} {selectedUserForNewEvaluation.apellido}</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowNewEvaluationModal(false)}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>

							<div className="modal-body">
								<form>
									<div className="form-group">
										<label htmlFor="peso">Peso:</label>
										<input type="number" className="form-control" id="peso" name="peso" placeholder="Ingrese el peso" />
									</div>
									<div className="form-group">
										<label htmlFor="igc">IGC:</label>
										<input type="number" className="form-control" id="igc" name="igc" placeholder="Ingrese el índice de grasa corporal (IGC)" />
									</div>
								</form>
							</div>

							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={() => {
									setShowNewEvaluationModal(false);
									resetModal();
								}}>
									Cerrar
								</button>
								<button type="button" className="btn btn-primary" onClick={() => {
									handleGuardarEvaluacion();
									resetModal();
								}}>
									Guardar Evaluación
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* Nuevo: Modal para mostrar las evaluaciones */}
			{selectedUserForConsultData && (
				<div className={`modal ${showConsultDataModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showConsultDataModal ? 'block' : 'none' }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Datos de Evaluación - {selectedUserForConsultData.nombre} {selectedUserForConsultData.apellido}</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowConsultDataModal(false)}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<h6>Evaluaciones del usuario:</h6>
								<ul>
									{userConsultDataEvaluations.map((evaluacion) => (
										<li key={evaluacion.id}>
											Fecha: {evaluacion.fechaDeEvaluacion}, Peso: {evaluacion.peso}, IGC: {evaluacion.igc}
										</li>
									))}
								</ul>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={() => { handleModalClose(); setShowConsultDataModal(false); }}>
									Cerrar
								</button>


							</div>
						</div>
					</div>
				</div >
			)
			}
		</>
	);
}

export default Coaches;