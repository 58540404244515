import React, { useState } from 'react';
import './styles/FooterMovile.css';
import { Link } from 'react-router-dom';


function FooterMovile({ onIconClick }) {
  const [selectedIcon, setSelectedIcon] = useState('clasesCreadas');

  const handleIconClick = (iconName) => {
    setSelectedIcon(iconName);
    onIconClick(iconName);
  };

  return (
    <div className='footer-mobile'>
      <i
        className={`fa-duotone fa fa-dumbbell ${selectedIcon === 'clasesCreadas' ? 'selected' : ''}`}
        onClick={() => handleIconClick('clasesCreadas')}
      ></i>

      <Link to='/'>
        <i className="ti-home icon-house"></i>
      </Link>

      <i
        className={`fa-solid fa fa-user ${selectedIcon === 'perfilSocio' ? 'selected' : ''}`}
        onClick={() => handleIconClick('perfilSocio')}
      ></i>

    </div>
  );
}

export default FooterMovile;