import React from 'react';
import './styles/CerrarSesion.css';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';


function CerrarSesion() {
	const { signOut } = useAuthenticator();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  return (
    <button id="cerrar-sesion" className="btn btn-primary boton-cerrar-sesion" onClick={handleSignOut}>
      <i className="ti-power-off"></i>
			<p>Cerrar Sesión</p>
    </button>
  );
};

export default CerrarSesion;