import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listClases, listInscritos } from './graphql/queries';
import { deleteClases } from './graphql/mutations';


const ClasesCreadasDeXBox = () => {
    const [clasesXBox, setClasesXBox] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [inscritos, setInscritos] = useState([]);
    // Modal para confirmar la eliminacion de la clase
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [classToDeleteTimestamp, setClassToDeleteTimestamp] = useState(null);
    const [classToDeleteInfo, setClassToDeleteInfo] = useState(false);

    const fetchXBoxClasses = async () => {
        try {
            const response = await API.graphql(graphqlOperation(listClases, {
                filter: {
                    tipo: { eq: 'X-Box' }
                }
            }));
            const clasesData = response.data.listClases.items;
            setClasesXBox(clasesData.reverse());
        } catch (error) {
            console.error('Error al obtener las clases de XBox:', error);
        }
    };

    const fetchInscritos = async (classTimestamp) => {
        try {
            const response = await API.graphql(graphqlOperation(listInscritos, {
                filter: {
                    tipo: { eq: 'X-Box' },
                    time_clase: { eq: classTimestamp }
                }
            }));
            const inscritosData = response.data.listInscritos.items;
            setInscritos(inscritosData);
        } catch (error) {
            console.error('Error al obtener los inscritos de la clase:', error);
        }
    };

    const handleOpenModal = async (classTimestamp) => {
        await fetchInscritos(classTimestamp);
        setModalOpen(true);
    };

    useEffect(() => {
        fetchXBoxClasses();

        const intervalId = setInterval(() => {
            fetchXBoxClasses();
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const handleDeleteClase = async (classTimestamp) => {
        try {
            // Obtener la información de la clase para mostrarla en el modal
            const claseInfo = clasesXBox.find((clase) => clase.fecha === classTimestamp);

            // Almacena el timestamp de la clase a borrar en el estado para referencia posterior
            setClassToDeleteTimestamp(classTimestamp);

            // Almacena la información de la clase a borrar en el estado para mostrar en el modal
            setClassToDeleteInfo(claseInfo);

            // Abre el modal de confirmación antes de eliminar la clase
            setConfirmationModalOpen(true);
        } catch (error) {
            console.error('Error al borrar la clase:', error);
        }
    };

    const handleConfirmDelete = async () => {
        try {
            // Realizar la solicitud de borrado utilizando la mutación deleteClases
            await API.graphql(graphqlOperation(deleteClases, {
                input: {
                    tipo: 'X-Box',
                    fecha: classToDeleteTimestamp
                }
            }));

            // Refrescar las clases después de borrar la clase
            await fetchXBoxClasses();
        } catch (error) {
            console.error('Error al borrar la clase:', error);
        } finally {
            // Cerrar el modal de confirmación después de la eliminación
            setConfirmationModalOpen(false);
        }
    };

    return (
        <div>
            {clasesXBox.map((clase) => (
                <div key={clase.id} className="card">
                    <div className="card-body">
                        <ul className="list-group list-group-horizontal-sm">
                            <li className="list-group-item">Fecha y Hora: {new Date(clase.fecha * 1000).toLocaleString()}</li>
                            <li className="list-group-item">Cupos Disponibles: {clase.cupo}</li>
                            <button type="button" className="btn btn-primary" onClick={() => handleOpenModal(clase.fecha)}>
                                Mostrar inscritos
                            </button>
                            <button type="button" className="btn btn-danger ml-2" onClick={() => handleDeleteClase(clase.fecha)}>
                                Borrar clase
                            </button>
                        </ul>
                    </div>
                </div>
            ))}
            {/* Modal de inscritos */}
            {modalOpen && (
                <div className="modal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Inscritos</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModalOpen(false)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {/* Mostrar la información de los inscritos aquí */}
                                <ul>
                                    {inscritos.map((inscrito) => (
                                        <li key={inscrito.id}>{`${inscrito.nombre} ${inscrito.apellido} - Teléfono: ${inscrito.phoneNumber}`}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setModalOpen(false)}>Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {confirmationModalOpen && (
                <div className="modal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirmar Eliminación</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setConfirmationModalOpen(false)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>{`¿Está seguro de que desea eliminar la clase de ${classToDeleteInfo ? classToDeleteInfo.tipo : ''} programada para ${classToDeleteInfo ? new Date(classToDeleteInfo.fecha * 1000).toLocaleString() : ''}?`}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Confirmar</button>
                                <button type="button" className="btn btn-secondary" onClick={() => setConfirmationModalOpen(false)}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ClasesCreadasDeXBox;